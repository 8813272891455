var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.device)?_c('b-container',{attrs:{"fluid":""}},[_c('h3',{staticClass:"mb-3"},[_vm._v("Dispositivo "+_vm._s(_vm.device.name)),(_vm.loadingData)?_c('b-spinner',{staticClass:"ml-3 mb-1",attrs:{"variant":"primary","small":""}}):_vm._e()],1),(_vm.device.status && _vm.device.usuario)?_c('b-card',{staticClass:"bg-light no-shadow px-3 py-1"},[_c('b-row',{directives:[{name:"b-toggle",rawName:"v-b-toggle.collapse-device-info",modifiers:{"collapse-device-info":true}}],staticClass:"align-items-center justify-content-between pointer"},[_c('span',[_vm._v(_vm._s(_vm.device.status.split("/")[0]))]),_c('chevron-down-icon',{staticClass:"icon-md"})],1),_c('b-collapse',{staticClass:"mt-3",attrs:{"id":"collapse-device-info"}},[_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.device.usuario.first_name)+" "+_vm._s(_vm.device.usuario.last_name))]),_c('p',{staticClass:"mb-1"},[_vm._v(_vm._s(_vm.device.usuario.email)+" - "+_vm._s(_vm.device.usuario.role))]),_c('p',{staticClass:"mb-0 uppercase"},[_vm._v(_vm._s(_vm.device.usuario.status))])])],1):_vm._e(),_c('div',[_c('validation-observer',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var handleSubmit = ref.handleSubmit;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.updateDevice)}}},[_c('b-row',{staticClass:"mb-5"},[_c('b-col',{staticClass:"mb-4",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Nombre"}},[_c('validation-provider',{attrs:{"name":"nombre","rules":"required|min:1"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Nombre","required":"","name":"name","state":errors[0] ? false : null},model:{value:(_vm.device.name),callback:function ($$v) {_vm.$set(_vm.device, "name", $$v)},expression:"device.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":"Cliente asignado"}},[_c('validation-provider',{attrs:{"name":"cliente","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{attrs:{"required":"","name":"cliente","options":_vm.parsedClientes,"state":errors[0] ? false : null},scopedSlots:_vm._u([{key:"first",fn:function(){return [_c('b-form-select-option',{attrs:{"disabled":"","value":null}},[_vm._v("-- Seleccionar cliente --")])]},proxy:true}],null,true),model:{value:(_vm.device.cliente_id),callback:function ($$v) {_vm.$set(_vm.device, "cliente_id", $$v)},expression:"device.cliente_id"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"mb-3",attrs:{"cols":"12"}},[_c('b-form-checkbox',{attrs:{"checked":_vm.willUpdate,"disabled":""}},[_vm._v("El dispositivo se actualizará la próxima vez que inicie sesión.")])],1)],1),_c('div',{staticClass:"text-right"},[_c('b-button',{staticClass:"text-danger mr-3",attrs:{"variant":"link"},on:{"click":_vm.deleteDevice}},[_vm._v("Eliminar dispositivo")]),_c('b-button',{attrs:{"variant":"primary","type":"submit"}},[_vm._v("Guardar cambios")])],1)],1)]}}],null,false,1417430758)})],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }