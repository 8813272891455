<template lang="pug">
  b-container(fluid v-if='device')
    h3.mb-3 Dispositivo {{ device.name }}
      b-spinner.ml-3.mb-1(variant="primary", small, v-if="loadingData")
    b-card.bg-light.no-shadow.px-3.py-1(v-if="device.status && device.usuario")
      b-row.align-items-center.justify-content-between.pointer(v-b-toggle.collapse-device-info)
        span {{ device.status.split("/")[0] }}
        chevron-down-icon.icon-md
      b-collapse.mt-3(id="collapse-device-info")
        p.mb-1 {{ device.usuario.first_name }} {{ device.usuario.last_name }}
        p.mb-1 {{ device.usuario.email }} - {{ device.usuario.role }}
        p.mb-0.uppercase {{ device.usuario.status }}
    div
      validation-observer(v-slot="{invalid, handleSubmit}")
        b-form(@submit.prevent="handleSubmit(updateDevice)")
          b-row.mb-5
            b-col.mb-4(cols="12")
              b-form-group(label="Nombre")
                validation-provider(name="nombre", rules="required|min:1", v-slot="{ errors }")
                  b-form-input(placeholder="Nombre", required, name="name", v-model="device.name", :state="errors[0] ? false : null")
                  b-form-invalid-feedback {{ errors[0] }}
            b-col.mb-3(cols="12")
              b-form-group(label="Cliente asignado")
                validation-provider(
                  name="cliente",
                  rules="required",
                  v-slot="{ errors }"
                )
                  b-form-select(
                    required,
                    name="cliente",
                    :options="parsedClientes",
                    v-model="device.cliente_id",
                    :state="errors[0] ? false : null",
                  )
                    template(#first)
                      b-form-select-option(disabled, :value="null") -- Seleccionar cliente --
                  b-form-invalid-feedback {{ errors[0] }}
            b-col.mb-3(cols='12')
              b-form-checkbox(:checked="willUpdate" disabled) El dispositivo se actualizará la próxima vez que inicie sesión.

          .text-right
            b-button.text-danger.mr-3(variant="link", @click="deleteDevice") Eliminar dispositivo
            b-button(variant="primary" type="submit") Guardar cambios
</template>

<script>
import { createNamespacedHelpers, mapActions } from 'vuex';
const deviceController = createNamespacedHelpers('device');
const clienteController = createNamespacedHelpers("cliente");

export default {
  data () {
    return {
      loadingData: false,
      device: null,
      initialName: '',
      initialCliente: null
    }
  },
  computed: {
    ...clienteController.mapState({
      clientes: (state) => state.clientes,
    }),
    parsedClientes() {
      return Array.isArray(this.clientes)
        ? this.clientes.map((c) => {
            return {
              value: c.cliente_id,
              text: c.name,
            };
          })
        : [];
    },
    willUpdate () {
      if (this.device.shouldUpdate) {
        return this.device.shouldUpdate
      } else {
        if (this.initialName !== this.device.name || this.initialCliente !== this.device.cliente_id) {
          return true
        }
      }

      return false
    }
  },
  methods: {
    ...mapActions(['cliente/get', 'device/update', 'device/get', 'device/destroy', "cliente/all",]),
    getCliente () {
      this.loadingData = true;
      this['cliente/get']({
        cliente_id: this.device.cliente_id,
        callback: res => {
          this.loadingData = false;

          if (res.success) {
            this.client = res.resource
          } else {
            this.$bvToast.toast(`Ocurrió un error obteniendo el cliente. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    getDevice () {
      this.loadingData = true;
      this['device/get']({
        device_id: this.$route.params.device_id,
        callback: res => {
          this.loadingData = false;

          if (res.success) {
            this.device = res.resource
            this.initialName = this.device.name
            if (this.device.cliente_id) {
              this.initialCliente = this.device.cliente_id
              this.getCliente()
            }
          } else {
            this.$bvToast.toast(`Ocurrió un error obteniendo el dispositivo. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    updateDevice () {
      this.loadingData = true;
      this['device/update']({
        device_id: this.$route.params.device_id,
        name: this.device.name,
        shouldUpdate: this.willUpdate,
        cliente_id: this.device.cliente_id,
        callback: res => {
          this.loadingData = false;
          if (res.success) {
            this.$bvToast.toast(`Dispositivo actualizado con éxito.`, {
              title: 'Actualizado',
              variant: 'success'
            })

            setTimeout(() => {
              this.$router.push({name: 'Devices'})
            }, 1000)
            
          } else {
            this.$bvToast.toast(`Ocurrió un error actualizando el dispositivo. Por favor intenta de nuevo.`, {
              title: 'Ocurrió un error',
              variant: 'danger'
            })
          }
        }
      })
    },
    deleteDevice () {
      this.$bvModal
        .msgBoxConfirm("¿Deseas eliminar el dispositivo? No se puede deshacer.", {
          okVariant: 'danger',
          okTitle: 'Eliminar'
        })
        .then((value) => {
          if (value == true) {
            this.loadingData = true;
            this['device/destroy']({
              device_id: this.$route.params.device_id,
              callback: res => {
                this.loadingData = false;
                if (res.success) {
                  this.$bvToast.toast(`Dispositivo eliminado con éxito.`, {
                    title: 'Eliminado',
                    variant: 'success'
                  })

                  setTimeout(() => {
                    this.$router.push({name: 'Devices'})
                  }, 1500)
                } else {
                  this.$bvToast.toast(`Ocurrió un error eliminando el dispositivo. Por favor intenta de nuevo.`, {
                    title: 'Ocurrió un error',
                    variant: 'danger'
                  })
                }
              }
            })
          }
        })
        .catch((err) => {
          // An error occurred
        });
    },
    getClientes() {
      this.loadingData = true;
      this["cliente/all"]({
        params: {
          page: 1,
          limit: 500,
        },
        callback: (res) => {
          this.loadingData = false;

          if (res.success) {
            // do something
          } else {
            this.$bvToast.toast(
              `Ocurrió un error obteniendo los clientes. Por favor intenta de nuevo.`,
              {
                title: "Ocurrió un error",
                variant: "danger",
              }
            );
          }
        },
      });
    },
  },
  mounted () {
    this.getDevice()
    this.getClientes()
  }
}
</script>

<style lang="scss" scoped>

</style>